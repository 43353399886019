<template>
    <div class="w-full">
        <!-- Header -->
        <div class="grid grid-cols-7 body-m-500 text-fonts-tertiary px-2 mb-5">
            <span class="col-span-2">Name</span>
            <span>Job Type</span>
            <span>Due Date</span>
            <span>Budgeted</span>
            <span>{{ paymentHeader }}</span>
            <span>Status</span>
        </div>
        <!-- Rows -->
        <div v-for="report in jobsReport" :key="report.id">
            <Row :report="report" :userIsPro="user.userable_type == 'pro'"/>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Row from '@/components/reports/tables/partials/Row';
export default {
    name:'ReportsTable',
    components: { Row, },
    computed: {
        ...mapState({
        user: (state) => state.auth.user,
        }),

        paymentHeader() {
            return this.user.userable_type == 'pro' ? 'Paid' : 'Payment';
        }
    },
    props: {
        jobsReport: Array
    },
}
</script>

<style lang="scss" scoped>

</style>