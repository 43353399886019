<template>
  <div class="min-h-screen">
    <div>
      <div class="flex flex-wrap md:flex-no-wrap justify-between items-center mb-10">
        <PageTitle title="Reports"/>
        
        <div class="rounded-full bg-white px-4 py-3 flex justify-between items-center w-full md:w-4/12 lg:w-3/12">
          <div @click.prevent="subtractMonth" class="cursor-pointer hover:text-primary">
            <ChevronLeftIcon/>
          </div>
          <span>{{currentMonth}}</span>
          <button @click.prevent="addMonth" type="button" :disabled="disabled" class="focus:outline-none" :class="disabled ? 'cursor-not-allowed text-primary-40' : 'cursor-pointer hover:text-primary'">
            <ChevronLeftIcon class="transform rotate-180"/>
          </button>
        </div>
      </div>
      <Table v-show="jobsReport.length" :jobsReport="jobsReport" />
      <!-- Empty State -->
      <div v-show="!jobsReport.length && !loading" class="flex flex-col items-center justify-center space-y-4 bg-white rounded-xl py-32">
        <img :src="require('@/assets/images/no_reports.png')" />
        <div class="body-l-500">Nothing to Show Here</div>
        <div class="body-s-400 text-fonts-secondary w-1/2 text-center">
            <p>
                There is no payment information available for the selected month.
            </p>
        </div>
        <ButtonIcon text="Return to Dashboard" @onClick="$router.push('/')">
          <template slot="leftIcon">
            <PlusIcon class="mr-2"/>
          </template>
        </ButtonIcon>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import PageTitle from '@/components/shared/labels/PageTitle.vue';
import ChevronLeftIcon from "@/components/shared/svg/ChevronLeftIcon";
import Table from '@/components/reports/tables/index';
import ButtonIcon from "@/components/shared/buttons/ButtonIcon";
import PlusIcon from "@/components/shared/svg/PlusIcon";
import { mapActions, mapState } from 'vuex';

export default {
  name: "Reports",
  components: { PageTitle, ChevronLeftIcon, Table, ButtonIcon, PlusIcon },
  data() {
    return {
      month: moment(),
      monthText: moment().format('YYYY-MM'),
      loading: false,
    }
  },

  async mounted() {
    this.loading = true;
    await this.fetchJobsReport(this.month.format('YYYY-MM-DD'));
    this.loading = false;
  },

  methods: {
    ...mapActions({
      fetchJobsReport: 'reports/fetchJobsReport',
    }),
    subtractMonth() {
      this.month = this.month.subtract(1, 'months');
      this.monthText = this.month.format('YYYY-MM-DD');
      this.fetchJobsReport(this.month.format('YYYY-MM-DD'));
    },

    addMonth() {
      this.month = this.month.add(1, 'months');
      this.monthText = this.month.format('YYYY-MM-DD');
      this.fetchJobsReport(this.month.format('YYYY-MM-DD'));
    },
  },

  computed: {
    ...mapState({
      jobsReport: state => state.reports.jobsReport,
    }),

    currentMonth() {
      var date = moment(this.monthText);

      return `${date.format('MMMM')} ${date.format('YYYY')}`
    },

    disabled() {
      return moment().diff(moment(this.monthText), 'months') <= 0
    },
  },
}
</script>

