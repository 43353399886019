<template>
        <div class="bg-white p-2 grid grid-cols-7 items-center rounded-xl mb-4">
            <div class="col-span-2 flex items-center">
                <AvatarImage :image="image" width="w-10" height="h-10" />
                <span class="ml-2">{{ name }}</span>
            </div>
            <span>{{ report.job_type }}</span>
            <span>{{ date }}</span>
            <span>${{ report.job_budget }}</span>
            <span>${{ report.payment_amount }}</span>
            <div class="text-center flex">
                <div class="rounded-xl py-1 px-3 capitalize" :class="status == 'paid' ? 'bg-functional-activeLight' : 'bg-secondary-light'">{{ status }}</div>
            </div>
        </div>
</template>

<script>
import AvatarImage from "@/components/shared/AvatarImage"
import moment from 'moment';
export default {
    name: 'ReportsRow',
    components: { AvatarImage, },
    props:{
        report: Object,
        userIsPro: { type: Boolean, default: false }
    },
    computed:{
        date() {
            return moment.utc(this.report.due_date).format("MMM DD, YYYY");
        },
        name() {
            return this.userIsPro ? this.report.church_name : this.report.pro_name;
        },
        image() {
            return this.userIsPro ? this.report.church_img : this.report.pro_img;
        },
        status() {
            return this.report.payment_status == 'succeeded' ? 'paid' : 'pending';
        }
    },
}
</script>
